<template>
    <main v-if="user" class="flex justify-center">
      <HeaderComponent />
      <section class="w-full lg:pl-[50px] lg:pt-[95px] pt-[95px] max-h-screen overflow-y-auto relative flex justify-start flex-col lg:p-[25px] p-[20px] h-auto min-h-screen bg-gray-200">
        <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[90%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <div class="w-full flex flex-col justify-center items-center relative">
          <div class="flex w-full justify-center items-center flex-col relative">
            <div class="flex flex-row justify-between  w-full relative items-center ">
              <div class="flex md:w-auto w-full items-center justify-start flex-wrap">
                <button :class="{'border-b-2 border-orange-600 text-orange-600': allOrder}" @click="toggleAllOrder()" class="text-sm font-bold text-gray-400 py-[5px] my-[5px]">Toutes les commandes</button>
                <button v-if="user.isSuper" :class="{'border-b-2 border-orange-600 text-orange-600': store}" @click="toggleStore()" class="text-sm font-bold text-gray-400 py-[5px] ml-[20px] mx-[10px] my-[5px]">Boutique</button>
                <button v-if="user.isSuper" :class="{'border-b-2 border-orange-600 text-orange-600': Cotonou}" @click="toggleCotonou()" class="text-sm font-bold text-gray-400 py-[5px] mx-[10px] my-[5px]">Cotonou</button>
                <button v-if="user.isSuper" :class="{'border-b-2 border-orange-600 text-orange-600': Parakou}" @click="toggleParakou()" class="text-sm font-bold text-gray-400 py-[5px] mx-[10px] my-[5px]">Parakou</button>
                <button v-if="user.isSuper" :class="{'border-b-2 border-orange-600 text-orange-600': online}" @click="toggleOnline()" class="text-sm font-bold text-gray-400 py-[5px] mx-[10px] my-[5px]">En ligne</button>
              </div>
              <div class="flex">
                <div v-if="user.isSuper" class="flex justify-center items-center">
                  <button @click="openModal" class="bg-blue-500 hidden md:flex text-sm md:mr-[20px] text-white font-bold py-2 md:px-4 px-[8px] rounded hover:bg-blue-700">
                    Générer un rapport
                  </button>
                
                  <div v-if="showModal" class="fixed inset-0 h-full w-full left-[50%] lg:left-[62%] translate-x-[-50%] top-[50%] translate-y-[-50%] flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div class="bg-white rounded-lg shadow-lg w-full max-w-md p-6 relative">
                      <h2 class="text-2xl font-bold mb-4">Générer un rapport</h2>
                      <form @submit.prevent="generateReport">
                        <div class="mb-4">
                          <label for="startDate" class="block text-sm font-medium text-gray-700">Date de début</label>
                          <input type="date" id="startDate" v-model="startDate" class="mt-1 block outline-none p-[10px] text-sm w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200" />
                        </div>
                        <div class="mb-6">
                          <label for="endDate" class="block text-sm font-medium text-gray-700">Date de fin</label>
                          <input type="date" id="endDate" v-model="endDate" class="mt-1 block outline-none p-[10px] text-sm w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200" />
                        </div>
                        <div class="flex justify-end">
                          <button type="button" @click="closeModal" class="mr-4 py-2 px-4 bg-gray-300 text-black rounded hover:bg-gray-400">Annuler</button>
                          <button type="submit" class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700">
                            Générer
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="md:flex justify-end hidden md:justify-center mt-[10px] md:mt-0 md:w-auto w-full  items-center">
                  <input type="date" v-model="selectedDate" @change="filterHistories" class="w-[150px] p-[5px] rounded-[5px] border border-gray-400 outline-none bg-transparent">
                </div>
              </div>

            </div>
            <div class="flex justify-start my-[10px] items-center w-full relative">
              <label for=""><i class="fa-solid fa-search text-sm font-bold text-gray-700 absolute left-[10px] top-[10px]"></i></label>
              <input type="text" name="" v-model="searchQuery" @input="searchSale" id="" placeholder="Recherchez un produit" class="w-full font-semibold max-w-[350px] rounded-[5px] bg-transparent border border-gray-400 outline-none pl-[30px] p-[10px] text-sm">
            </div>
            <div class="py-[20px] w-full flex justify-center items-center relative">
              <ul class="w-full relative flex flex-col justify-center items-center">
                <li class="flex justify-between items-center w-full relative my-[10px] rounded-[10px] border border-gray-300 bg-gray-200 p-[10px]">
                  <div class="md:flex justify-start items-center p-[5px] text-sm font-bold text-gray-800 md:w-[60px] hidden">ID</div>
                  <div class="flex justify-start items-center p-[5px] text-sm font-bold text-gray-800 md:w-[calc(50%-195px)] w-[40px]">Nom</div>
                  <div class="flex justify-start items-center p-[5px] text-sm font-bold text-gray-800 md:w-[calc(50%-190px)] w-[calc(100%-205px)]">Produits</div>
                  <div class="md:flex justify-start items-center p-[5px] text-sm font-bold text-gray-800 md:w-[60px] hidden">Type</div>
                  <div class="md:flex justify-start items-center p-[5px] text-sm font-bold text-gray-800 md:w-[70px] hidden">Paiement</div>
                  <div class="flex justify-start items-center p-[5px] text-sm font-bold text-gray-800 md:w-[55px]">Status</div>
                  <div class="flex justify-start items-center p-[5px] text-sm font-bold text-gray-800 md:w-[80px]">Total(<span class="text-[9px]">FCFA</span>)</div>
                  <div v-if="user.isSuper" class="flex justify-start items-center p-[5px] text-sm font-bold text-gray-800 md:w-[60px] w-[30px]"><span class="md:flex hidden">Action</span><span class="md:hidden flex">Act</span></div>
                </li>
                <li v-for="(history, index) in filteredHistories" :key="index" class="flex justify-between items-center w-full relative my-[10px] rounded-[10px] border border-gray-300 bg-gray-200 p-[10px]">
                  <div class="md:flex justify-start items-center p-[5px] text-sm font-bold text-gray-800 md:w-[60px] hidden"># {{ index +1 }}</div>
                  <div class="flex justify-start items-center p-[5px] text-sm md:w-[calc(50%-195px)] w-[40px]">
                    <div class="md:w-[40px] md:h-[40px] w-[30px] h-[30px] md:min-w-[40px] md:min-h-[40px] min-w-[30px] min-h-[30px] rounded-[50%] relative overflow-hidden border border-white flex justify-center items-center">
                      <img v-if="history.vendeur.boutique === 'Cotonou'" src="../assets/images/cotonou.jpg" alt="" class="w-full h-full absolute object-cover object-center rounded-[50%]">
                      <img v-if="history.vendeur.boutique === 'Parakou'" src="../assets/images/cotonou.jpg" alt="" class="w-full h-full absolute object-cover object-center rounded-[50%]">
                      <i v-if="history.vendeur.boutique === 'En ligne'" class="fa-solid fa-globe-africa text-3xl"></i>
                    </div>
                    <div v-if="history.client" class="ml-[10px] hidden md:flex flex-col items-start">
                      <h1 class="text-gray-800 font-bold text-sm">{{ history.client.prenoms }} {{ history.client.nom }}</h1>
                      <p class="text-xs text-gray-500">
                        <span v-if="history.vendeur" :class="{'hidden': history.vendeur.boutique === 'En ligne'}">
                          {{ history.vendeur.prenoms }} {{ history.vendeur.nom }} - 
                        </span>
                        {{ history.vendeur?.boutique }}
                      </p>
                    </div>
                  </div>
                  <div class="flex justify-start items-center p-[5px] text-sm font-semibold text-gray-500 md:w-[calc(50%-190px)] w-[calc(100%-205px)]">{{ history.produits.map((p) => `${p.lotName} (${p.quantity})`).join(', ') }}</div>
                  <div class="md:flex justify-start items-center p-[5px] text-sm font-semibold text-gray-700 md:w-[60px] hidden">{{history.type}}</div>
                  <div class="md:flex justify-start items-center p-[5px] text-sm font-semibold text-gray-700 md:w-[70px] hidden">{{ history.moyenDePaiement }}</div>
                  <div class="flex justify-start items-center p-[5px] text-sm font-semibold text-gray-700 w-[45px] min-w-[45px]"><span class="p-[5px] rounded-[2px]" :class="{'bg-green-600 text-white': history.isValide , 'bg-blue-600 text-white': !history.isValide}"><span v-if="history.isValide">Suc</span><span v-else>Pen</span></span></div>
                  <div class="flex justify-start items-center p-[5px] text-sm font-semibold text-gray-800 w-[60px] min-w-[60px]">{{ history.totalApresReduction }}</div>
                  <div class="flex justify-center items-center p-[5px] text-sm font-semibold text-gray-800 md:w-[60px] w-[30px] md:min-w-[60px] min-w-[30px]"><button @click="toggleBox(history._id)" class="w-[25px] h-[25px] min-w-[25px] min-h-[25px] rounded-[50%] bg-gray-100"><i v-if="showBoxId === history._id" class="fa-solid fa-times"></i> <i v-else class="fa-solid fa-ellipsis-vertical cursor-pointer"></i></button></div>
                  <div v-if="showBoxId === history._id" class="w-[550px] max-w-[85%] h-auto max-h-[85%] overflow-y-auto parent bg-white fixed text-xs font-bold left-[50%] lg:left-[62%] translate-x-[-50%] top-[calc(55%)] translate-y-[-50%]  z-30 shadow-4xl p-[20px] rounded-[5px] duration-300 flex flex-col items-start">
                    <button @click="toggleBox(history._id)" class="w-[35px] absolute top-[0px] z-40 left-[50%] translate-x-[-50%] h-[35px] rounded-[50%] bg-gray-100"><i v-if="showBoxId === history._id" class="fa-solid fa-times"></i> <i v-else class="fa-solid fa-ellipsis-vertical cursor-pointer"></i></button>
                    <div v-if="user.isSuper" @click="toggleActive(history._id)" class="w-full mt-[20px] min-h-[40px] rounded-[5px] overflow-hidden">
                      <button v-if="history.isValide" class="bg-red-600 text-white w-full p-[10px]">Rejecter</button>
                      <button v-else class="bg-green-600 text-white w-full p-[10px]">Valider</button>
                    </div>
                    <button @click="deleteHistorique(history._id)" v-if="!history.isValide && user.isSuper" class="relative p-[10px] w-full text-xs font-bold text-white bg-red-600 mt-[10px] rounded-[5px] border flex justify-center items-center border-gray-300">
                      <i class="fa-solid fa-trash mr-[5px] font-bold"></i>
                      Supprimer
                    </button>
                    <div>
                      <h1 class="text-sm font-bold text-gray-600 my-[5px]">
                        IMEI1: 
                        {{ history.produits
                            .filter((p) => p.imei1)
                            .map((p) => `${p.lotName} (${p.imei1})`)
                            .join(', ') }}
                      </h1>
                      <h1 class="text-sm font-bold text-gray-600 my-[5px]">
                        IMEI2: 
                        {{ history.produits
                            .filter((p) => p.imei2) 
                            .map((p) => `${p.lotName} (${p.imei2})`)
                            .join(', ') }}
                      </h1>
                    </div>
                    <div class="md:flex items-start justify-center w-full relative">
                      <div class="md:w-6/12 w-full my-[10px] pr-[10px] font-normal">
                        <h1 class="text-gray-800 text-sm font-bold mb-[10px]">Détails sur le client</h1>
                        <div class="flex items-center my-[5px]">
                          <i class="fa-solid fa-user mr-[5px] text-xs font-bold"></i>
                          <p>{{ history.client.nom }} {{ history.client.prenoms }}</p>
                        </div>
                        <div class="flex items-center my-[5px]">
                          <i class="fa-solid fa-envelope mr-[5px] text-xs font-bold"></i>
                          <p>{{ history.client.email }}</p>
                        </div>
                        <div class="flex items-center my-[5px]">
                          <i class="fa-solid fa-phone mr-[5px] text-xs font-bold"></i>
                          <p>{{ history.client.numero }}</p>
                        </div>
                        <div class="flex items-center my-[5px]">
                          <i class="fa-solid fa-location-dot mr-[5px] text-xs font-bold"></i>
                          <p>{{ history.client.adresse }}</p>
                        </div>
                      </div>
                      <div class="md:w-6/12 w-full my-[10px] pr-[10px] font-normal">
                        <h1 class="text-gray-800 text-sm font-bold mb-[10px]">Détails sur le vendeur</h1>
                        <div class="flex items-center my-[5px]">
                          <i class="fa-solid fa-user mr-[5px] text-xs font-bold"></i>
                          <p>{{ history.vendeur.nom }} {{ history.vendeur.prenoms }}</p>
                        </div>
                        <div class="flex items-center my-[5px]">
                          <i class="fa-solid fa-envelope mr-[5px] text-xs font-bold"></i>
                          <p>{{ history.vendeur.email }}</p>
                        </div>
                        <div class="flex items-center my-[5px]">
                          <i class="fa-solid fa-phone mr-[5px] text-xs font-bold"></i>
                          <p>{{ history.vendeur.numero }}</p>
                        </div>
                        <div class="flex items-center my-[5px]">
                          <i class="fa-solid fa-store mr-[5px] text-xs font-bold"></i>
                          <p>{{ history.vendeur.boutique }}</p>
                        </div>
                        <div class="flex items-center my-[5px]">
                          <i class="fa-solid fa-briefcase mr-[5px] text-xs font-bold"></i>
                          <p>{{ history.vendeur.poste }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="md:flex items-start justify-center w-full relative">
                      <h1 class="text-left w-full">{{history.createdAt}}</h1>
                    </div>
                    <div class="my-[10px] w-full flex flex-col items-start relative">
                      <div v-for="(produit, index) in history.produits" :key="index" class="flex w-full items-center p-[2px] my-[5px] bg-gray-100 rounded-[2px]">
                        <div class="w-[40px] h-[40px] min-w-[40px] rounded-[2px] min-h-[40px] relative overflow-hidden border border-white flex justify-center items-center">
                          <img v-if="history.vendeur.boutique === 'Cotonou'" src="../assets/images/cotonou.jpg" alt="" class="w-full h-full absolute object-cover object-center">
                          <img v-if="history.vendeur.boutique === 'Parakou'" src="../assets/images/cotonou.jpg" alt="" class="w-full h-full absolute object-cover object-center">
                          <i v-if="history.vendeur.boutique === 'En ligne'" class="fa-solid fa-globe-africa text-3xl"></i>
                        </div>
                        <div class="pl-[10px]">
                          <h1 class="font-semibold text-sm text-gray-800">{{ produit.lotName }} ( {{ produit.quantity }} )</h1>
                          <p class="text-xs font-semibold text-gray-500">
                            {{ produit.stotal }} | <span v-if="produit.capacities"> {{ produit.capacities[0] }} </span> <span v-if="produit.colors"> | {{ produit.colors[0] }} | </span> <span> {{ produit.stock }} </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="p-[5px] bg-orange-600/10 rounded-[5px] py-[10px] w-full text-orange-600 font-bold text-sm">
                      Total: {{ history.totalApresReduction }} FCFA
                    </div>
                  </div>
                </li>
                <div v-if="filteredHistories.length == 0 && !isLoading" class="w-full h-[200px] flex justify-center items-center text-sm font-bold text-gray-800">
                  Aucune vente trouvée
                </div>
                <div v-if="isLoading" class="w-full min-h-[200px] flex justify-center items-center">
                    <div class="spinner22"></div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
  </template>
  
<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import HeaderComponent from '../components/HeaderComponent.vue';
import { useAuthStore } from '@/store/auth';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const showBoxId = ref(null);
const allOrder = ref(true);
const store = ref(false);
const online = ref(false);
const Cotonou = ref(false);
const Parakou = ref(false);
const histories = ref([]);
const filteredHistories = ref([]);
const selectedDate = ref(null);
const errorMessage = ref('')
const successMessage = ref('')
const isLoading = ref(false)

function toggleBox(id) {
  showBoxId.value = showBoxId.value === id ? null : id;
}

function toggleAllOrder() {
  allOrder.value = true;
  store.value = false;
  online.value = false;
  Cotonou.value = false;
  Parakou.value = false;
  filterHistories();
}

function toggleStore() {
  allOrder.value = false;
  store.value = true;
  online.value = false;
  Cotonou.value = false;
  Parakou.value = false;
  filterHistories();
}

function toggleCotonou() {
  allOrder.value = false;
  store.value = false;
  online.value = false;
  Cotonou.value = true;
  Parakou.value = false;
  filterHistories();
}

function toggleParakou() {
  allOrder.value = false;
  store.value = false;
  online.value = false;
  Cotonou.value = false;
  Parakou.value = true;
  filterHistories();
}

function toggleOnline() {
  allOrder.value = false;
  store.value = false;
  online.value = true;
  Cotonou.value = false;
  Parakou.value = false;
  filterHistories();
}

async function fetchHistories() {
  isLoading.value = true
  try {
    const response = await axios.get('/api/sales');
    histories.value = response.data;
    filterHistories();
  } catch (error) {
    errorMessage.value = 'Erreur lors du chargement des ventes';
    hideErrorMessageAfterDelay()
  } finally {
    isLoading.value = false
  }
}

async function fetchHistoriesbyVendeur() {
  try {
    let url = '/api/sales/vendeur';
    
    if (user.value.lastname || user.value.lastname) {
      url = `/api/sales/vendeur?nom=${user.value.lastname}&prenoms=${user.value.firstname}`;
    }

    const response = await axios.get(url);
    histories.value = response.data;
    filterHistories();
  } catch (error) {
    errorMessage.value = 'Erreur lors du chargement des ventes';
    hideErrorMessageAfterDelay()
  }
}

function filterHistories() {
  filteredHistories.value = histories.value.filter(history => {
    // Filtrer par boutique Cotonou ou Parakou
    if (Cotonou.value && history.vendeur.boutique !== 'Cotonou') {
      return false;
    }
    if (Parakou.value && history.vendeur.boutique !== 'Parakou') {
      return false;
    }
    
    // Filtrer les ventes en ligne
    if (online.value && history.vendeur.boutique !== 'En ligne') {
      return false;
    }

    // Filtre par date (si nécessaire)
    if (selectedDate.value) {
      const historyDate = new Date(history.createdAt).toISOString().split('T')[0];
      if (historyDate !== selectedDate.value) {
        return false;
      }
    }

    return true;
  });
}

const deleteHistorique = async (id) => {
  try {
    const response = await axios.delete(`/api/sales/${id}`);
    if(user.value.isSuper) {
      fetchHistories()
    } else {
      fetchHistoriesbyVendeur()
    }
    showBoxId.value = null
  } catch (error) {
    errorMessage.value = 'Erreur lors de la suppression de la vente.';
    hideErrorMessageAfterDelay()
  }
};

const toggleActive = async (id) => {
  try {
    await axios.put(`/api/sales/${id}/toggle-active/`);
    const index = histories.value.findIndex((e) => e._id === id);
    histories.value[index].isValide = !histories.value[index].isValide;
    fetchHistories();
    showBoxId.value = null;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      errorMessage.value = error.response.data.error;
    } else {
      errorMessage.value = 'Une erreur est survenue lors du changement de statut.';
    }
    hideErrorMessageAfterDelay();
  }
};

onMounted(() => {
  if(user.value.isSuper) {
    fetchHistories()
  } else {
    fetchHistoriesbyVendeur()
  }
});

const showModal = ref(false);
const startDate = ref('');
const endDate = ref('');

function openModal() {
  showModal.value = true;
}

function closeModal() {
  showModal.value = false;
}

async function generateReport() {
  try {
    const response = await axios.post('/api/export-sales', {
      startDate: startDate.value,
      endDate: endDate.value
    }, {
      responseType: 'blob'
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `rapport_ventes_${startDate.value}_à_${endDate.value}.xlsx`);
    document.body.appendChild(link);
    link.click();

    closeModal();
  } catch (error) {
    errorMessage.value = 'Erreur lors de la génération du rapport';
    hideErrorMessageAfterDelay()
  }
}


const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 5000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 10000);
};

const searchQuery = ref('');

const searchSale = () => {
  const query = searchQuery.value.toLowerCase(); // Chaîne de recherche en minuscule

  filteredHistories.value = histories.value
    .map(sale => {
      // Filtrer les produits de la vente en fonction de la recherche sur les IMEI
      const filteredProduits = sale.produits.filter(produit => {
        const matchesImei1 = produit.imei1?.toLowerCase().includes(query); // Correspondance sur imei1
        const matchesImei2 = produit.imei2?.toLowerCase().includes(query); // Correspondance sur imei1
        const matchesName = produit.lotName?.toLowerCase().includes(query); // Correspondance sur imei2

        return matchesImei1 || matchesImei2 || matchesName; // Retourner si l'un des deux IMEI correspond
      });

      // Vérifier si le nom du vendeur ou du client correspond à la recherche
      const matchesVendeurName = `${sale.vendeur.nom.toLowerCase()} ${sale.vendeur.prenoms.toLowerCase()}`.includes(query);
      const matchesClientName = `${sale.client.nom.toLowerCase()} ${sale.client.prenoms.toLowerCase()}`.includes(query);

      // Si la vente a des produits correspondant à la recherche ou si le vendeur ou client correspond, retourner la vente avec les produits filtrés
      if (filteredProduits.length > 0 || matchesVendeurName || matchesClientName) {
        return {
          ...sale,
          produits: filteredProduits.length > 0 ? filteredProduits : sale.produits // Si des produits correspondent, les inclure, sinon garder tous les produits
        };
      }

      // Si aucun produit ne correspond et ni le vendeur ni le client ne correspondent, ne pas inclure cette vente dans les résultats
      return null;
    })
    .filter(sale => sale !== null); // Exclure les ventes non correspondantes
};


</script>

<style scoped>
/* Add your styles here */
.spinner22 {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: rgb(234, 88, 12);
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
  